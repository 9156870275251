<template>
  <div :class="{ 'mdc-select--disabled': disabled }" class="mdc-select mb-2">
    <i class="mdc-select__dropdown-icon text-gray-light" />
    <select
      :id="inputId"
      v-model="model"
      :aria-controls="`${inputId}-helper-text`"
      :aria-describedby="`${inputId}-helper-text`"
      :disabled="disabled"
      class="mdc-select__native-control bg-white"
      @change="
        $emit(
          'input',
          options.find((option) => option[valueKey] === model)
        )
      "
    >
      <option
        v-for="option in options"
        :key="option[valueKey]"
        :value="option[valueKey]"
        v-text="option[labelKey]"
      />
    </select>
    <label :for="inputId" class="mdc-floating-label" v-text="label" />
    <div class="mdc-line-ripple" />
  </div>
</template>

<script>
import { MDCSelect } from '@material/select';

export default {
  props: {
    id: {
      type: String,
      default: null,
    },
    value: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
    valueKey: {
      type: String,
      default: 'id',
    },
    labelKey: {
      type: String,
      default: 'label',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    model: null,
    mdcSelect: null,
  }),

  computed: {
    inputId() {
      return this.id || this._uid;
    },
  },

  mounted() {
    this.model = this.value[this.valueKey];
    this.$nextTick(() => {
      this.mdcSelect = new MDCSelect(this.$el);
    });
  },

  beforeDestroy() {
    if (this.mdcSelect) this.mdcSelect.destroy();
  },
};
</script>
